import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule, TRANSLATIONS, TRANSLATIONS_FORMAT } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule, MatPaginatorIntl } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { ErrorDisplayModule } from './error-display/error-display.module';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './login/login.component';
import { ApiService } from './services/api.service';
import { ExportApiService } from './services/export.service';
import { TokenInterceptor } from './services/interceptor';
import { BasicAuthInterceptor } from './shared/basic-auth.interceptor';
import { ErrorInterceptor } from './shared/error.interceptor';
import { HttpErrorHandler } from './shared/http-error-handler.service';
import { MatPaginatorIntlService } from './shared/paginator.intl';
import { TranslationUtils } from './shared/translation.utils';

declare const require;

@NgModule({
  declarations: [AppComponent, AdminLayoutComponent, LoginComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ComponentsModule,
    HttpClientModule,
    CommonModule,
    RouterModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    ErrorDisplayModule,
  ],
  providers: [
    ApiService,
    ExportApiService,
    HttpErrorHandler,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: TRANSLATIONS_FORMAT, useValue: 'xlf',
    },
    { provide: TRANSLATIONS, useFactory: (locale: string) => {
        locale = locale || 'en'; // default to english if no locale provided
        locale = locale.substring(0, 2);
        return require(`raw-loader!../locale/messages.${locale}.xlf`);
      },
      deps: [LOCALE_ID],
    },
    I18n,
    {
      provide: MatPaginatorIntl, useClass: MatPaginatorIntlService,
    },
    TranslationUtils,
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
