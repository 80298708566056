import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './shared/auth.guard';

const routes: Routes = [

  // { path: '', component: AdminLayoutComponent, canActivate: [AuthGuard], redirectTo: 'dashboard' },
  { path: 'login', component: LoginComponent },
  { path: 'logout', redirectTo: 'login' }, // if login called, automatically logout
  { path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [ {
      path: '',
      loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule',
    } ],
  },
  // otherwise redirect to home
  { path: '**', component: AdminLayoutComponent },
];

export const routing = RouterModule.forRoot( routes, {useHash: true} );

@NgModule( {
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot( routes , {useHash: true}),
  ],
  exports: [
  ],
} )

export class AppRoutingModule { }
