var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorDisplayComponent } from './error-display.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/snack-bar";
var ErrorDisplayService = /** @class */ (function () {
    function ErrorDisplayService(snackBar) {
        this.snackBar = snackBar;
    }
    ErrorDisplayService.prototype.open = function (message) {
        var config = {
            panelClass: ['error-display'],
            duration: 10000,
            verticalPosition: 'top',
        };
        this.snackBarRef = this.snackBar.openFromComponent(ErrorDisplayComponent, __assign({ data: message }, config));
        /*
                this.snackBarRef.afterOpened().subscribe(() => {
                    console.log('The snack-bar was opened!');
                });
        
                this.snackBarRef.afterDismissed().subscribe(() => {
                    console.log('The snack-bar was dismissed');
                });
        */
    };
    ErrorDisplayService.ngInjectableDef = i0.defineInjectable({ factory: function ErrorDisplayService_Factory() { return new ErrorDisplayService(i0.inject(i1.MatSnackBar)); }, token: ErrorDisplayService, providedIn: "root" });
    return ErrorDisplayService;
}());
export { ErrorDisplayService };
