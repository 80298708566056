var ɵ0 = function (locale) {
    locale = locale || 'en'; // default to english if no locale provided
    locale = locale.substring(0, 2);
    return require("raw-loader!../locale/messages." + locale + ".xlf");
};
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
