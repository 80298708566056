import { Component, OnInit } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';

declare const $: any;
declare interface IRouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor(private i18n: I18n) { }

  ngOnInit() {
    const routes: IRouteInfo[] = this.createRoutes();
    this.menuItems = routes.filter((menuItem) => menuItem);
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  private createRoutes(): IRouteInfo[] {
    return [
      { path: '/dashboard', title: this.i18n({value: 'Dashboard', id: 'sidebar_dashboard'}), icon: 'dashboard', class: '' },
      { path: '/invoice-list', title: this.i18n({value: 'Invoices', id: 'sidebar_invoice-list'}), icon: 'list', class: '' },
      { path: '/period-list', title: this.i18n({value: 'Accounting Periods', id: 'sidebar_period-list'}), icon: 'list', class: '' },
      { path: '/logout', title: this.i18n({value: 'Logout', id: 'sidebar_logout'}), icon: 'logout', class: '' },
    ];
  }
}
