import { Injectable } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';

@Injectable()
export class TranslationUtils {

  static readonly NETWORK_ERROR: string = 'NETWORK_ERROR';
  static readonly UNKNOWN_ERROR: string = 'UNKNOWN_ERROR';
  static readonly NOT_AUTHORIZED_ERROR: string = 'NOT_AUTHORIZED_ERROR';

  private invoiceExportColumnHeaders: string;
  private billingExportColumnHeaders: string;
  private readonly columnHeaderMap: Map<string, string>;
  private errorMap: Map<string, string>;

  constructor(private readonly i18n: I18n) {
    this.columnHeaderMap = this.translateColumnHeaders();
  }

  public getBillingExportColumnHeaders(): string {
    if (!this.billingExportColumnHeaders) {
      this.billingExportColumnHeaders = this.createBillingExportColumnHeaders();
    }
    return this.billingExportColumnHeaders;
  }

  public getInvoiceExportColumnHeaders(): string {
    if (!this.invoiceExportColumnHeaders) {
      this.invoiceExportColumnHeaders = this.createInvoiceExportColumsHeaders();
    }
    return this.invoiceExportColumnHeaders;
  }

  private createBillingExportColumnHeaders(): string {
    return this.columnHeaderMap.get('id')
      + '\t' + this.columnHeaderMap.get('reception_id')
      + '\t' + this.columnHeaderMap.get('accounting_time')
      + '\t' + this.columnHeaderMap.get('statement_id')
      + '\t' + this.columnHeaderMap.get('upload_time');
  }

  private createInvoiceExportColumsHeaders(): string {
    let headers = '';
    this.columnHeaderMap.forEach((value: string, key: string) => {
      headers += value + '\t';
    });
    return headers;
  }

  public translateError(error_code: string) {
    if (!this.errorMap) {
      this.errorMap = this.translateErrors();
    }

    return (this.errorMap.get(error_code) ? this.errorMap.get(error_code) : this.errorMap.get(TranslationUtils.UNKNOWN_ERROR));
  }

  private translateColumnHeaders(): Map<string, string> {
    return new Map([
      ['id', this.i18n({value: 'id', id: 'tsv_col-header_id'})],
      ['reception_id', this.i18n({value: 'reception_id', id: 'tsv_col-header_reception_id'})],
      ['accounting_time', this.i18n({value: 'accounting_time', id: 'tsv_col-header_accounting_time'})],
      ['statement_id', this.i18n({value: 'statement_id', id: 'tsv_col-header_statement_id'})],
      ['upload_time', this.i18n({value: 'upload_time', id: 'tsv_col-header_upload_time'})],
      ['hash', this.i18n({value: 'hash', id: 'tsv_col-header_hash'})],
      ['signed_file_hash', this.i18n({value: 'signed_file_hash', id: 'tsv_col-header_signed_file_hash'})],
      ['initial_expense_hash', this.i18n({value: 'initial_expense_hash', id: 'tsv_col-header_initial_expense_hash'})],
      ['description', this.i18n({value: 'description', id: 'tsv_col-header_description'})],
      ['invoice_number', this.i18n({value: 'invoice_number', id: 'tsv_col-header_invoice_number'})],
      ['total_amount', this.i18n({value: 'total_amount', id: 'tsv_col-header_total_amount'})],
      ['total_amount_currency', this.i18n({value: 'total_amount_currency', id: 'tsv_col-header_total_amount_currency'})],
      ['country', this.i18n({value: 'country', id: 'tsv_col-header_country'})],
      ['doc_type', this.i18n({value: 'doc_type', id: 'tsv_col-header_doc_type'})],
      ['expense_type', this.i18n({value: 'expense_type', id: 'tsv_col-header_expense_type'})],
      ['creation_date', this.i18n({value: 'creation_date', id: 'tsv_col-header_creation_date'})],
      ['invoice_date', this.i18n({value: 'invoice_date', id: 'tsv_col-header_invoice_date'})],
      ['recipient_header', this.i18n({value: 'recipient_header', id: 'tsv_col-header_recipient_header'})],
      ['recipient_country', this.i18n({value: 'recipient_country', id: 'tsv_col-header_recipient_country'})],
      ['recipient_tax_id', this.i18n({value: 'recipient_tax_id', id: 'tsv_col-header_recipient_tax_id'})],
      ['vendor_header', this.i18n({value: 'vendor_header', id: 'tsv_col-header_vendor_header'})],
      ['vendor_country', this.i18n({value: 'vendor_country', id: 'tsv_col-header_vendor_country'})],
      ['vendor_tax_id', this.i18n({value: 'vendor_tax_id', id: 'tsv_col-header_vendor_tax_id'})],
      ['price_rate', this.i18n({value: 'price_rate', id: 'tsv_col-header_price_rate'})],
      ['price_gross_amount', this.i18n({value: 'price_gross_amount', id: 'tsv_col-header_price_gross_amount'})],
      ['price_vat_amount', this.i18n({value: 'price_vat_amount', id: 'tsv_col-header_price_vat_amount'})],
      ['price_net_amount', this.i18n({value: 'price_net_amount', id: 'tsv_col-header_price_net_amount'})],
      ['price_currency', this.i18n({value: 'price_currency', id: 'tsv_col-header_price_currency'})],
    ]);
  }

  private translateErrors(): Map<string, string> {
    return new Map([
      ['DATABASE_UNAVAILABLE', this.i18n({value: 'DATABASE_UNAVAILABLE', id: 'error_database_unavailable'})],
      ['SYSTEM_NOT_AVAILABLE', this.i18n({value: 'SYSTEM_NOT_AVAILABLE', id: 'error_system_not_available'})],
      ['INTERNAL_SERVER_ERROR', this.i18n({value: 'INTERNAL_SERVER_ERROR', id: 'error_internal_server_error'})],
      [TranslationUtils.UNKNOWN_ERROR, this.i18n({value: 'UNKNOWN_ERROR', id: 'error_unknown_error'})],
      [TranslationUtils.NOT_AUTHORIZED_ERROR, this.i18n({value: 'NOT_AUTHORIZED_ERROR', id: 'error_not_authorized_error'})],
      [TranslationUtils.NETWORK_ERROR, this.i18n({value: 'NETWORK_ERROR', id: 'error_network_error'})],
      ]);
  }
}
