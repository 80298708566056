import { MatSnackBarRef } from '@angular/material/snack-bar';
var ErrorDisplayComponent = /** @class */ (function () {
    function ErrorDisplayComponent(snackBarRef, data) {
        this.snackBarRef = snackBarRef;
        this.data = data;
    }
    ErrorDisplayComponent.prototype.dismiss = function () {
        this.snackBarRef.dismiss();
    };
    return ErrorDisplayComponent;
}());
export { ErrorDisplayComponent };
