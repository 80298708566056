import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ErrorDisplayService } from '../error-display/error-display.service';
import { TranslationUtils } from '../shared/translation.utils';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private translationUtils: TranslationUtils,
    private errorDisplayService: ErrorDisplayService,
  ) {}

  errorOpen(error_code: string) {
    const errorMessage = this.translationUtils.translateError(error_code);
    this.errorDisplayService.open(errorMessage);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error.status === 401) {
          // Unauthorized
          // auto logout if 401 response returned from api
          this.authenticationService.logout();
          this.errorOpen(TranslationUtils.NOT_AUTHORIZED_ERROR);
          this.router.navigate(['/login']);
        } else if (error.status === 0) {
          this.errorOpen(TranslationUtils.NETWORK_ERROR);
        } else {
          this.errorOpen(
            (error.error && error.error.err && error.error.err.error_code)
              ? error.error.err.error_code
              : TranslationUtils.UNKNOWN_ERROR);
        }
        return throwError(error);
      }),
    );
  }
}
