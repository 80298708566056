import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';

@Injectable()
export class MatPaginatorIntlService extends MatPaginatorIntl {

  constructor(private readonly i18n: I18n) {
    super();
    this.itemsPerPageLabel = this.i18n({value: 'Items per page:', id: 'table-paginator_label_items-per-page' });
    this.nextPageLabel = this.i18n({value: 'Next page', id: 'table-paginator_label_next-page' });
    this.previousPageLabel = this.i18n({value: 'Previous page', id: 'table-paginator_label_previous-page' });
    this.firstPageLabel = this.i18n({value: 'First page', id: 'table-paginator_label_first-page' });
    this.lastPageLabel = this.i18n({value: 'Last page', id: 'table-paginator_label_last-page' });
  }

  getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return this.i18n({value: '0 of {{length}}', id: 'paginator_label_zero-range' }, { length });
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return this.i18n({value: '{{startIndex}} - {{endIndex}} of {{length}}', id: 'paginator_label_ramge' },
      { startIndex: startIndex + 1, endIndex, length });
  }
}
