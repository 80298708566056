import { formatDate } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment as env } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    constructor(private http: HttpClient) { }

    private loginUrl = `${env.apiUrl}/authentication/system`;

    login(username: string, password: string) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' +  btoa(username + ':' + password),
          }),
        };

        return this.http.get<any>(this.loginUrl, httpOptions)
        .pipe(map((data) => {
            // login successful if there's a user in the response
            if (data) {
              localStorage.setItem('auditBasicAuth', window.btoa(username + ':' + password));
              localStorage.setItem('auditSystem', JSON.stringify(data));
              localStorage.setItem('auditSystemSelected', data[0]);
              localStorage.setItem('auditUser', username);
              localStorage.setItem('lastLogin', formatDate(new Date(), 'medium', 'en-us'));
            }
            return data;
        }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('auditBasicAuth');
        localStorage.removeItem('auditSystem');
        localStorage.removeItem('auditSystemSelected');
        localStorage.removeItem('auditUser');
        localStorage.removeItem('lastLogin');
    }
}
