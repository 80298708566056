import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Moment } from 'moment';
import { EMPTY, Observable, of } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { SatDatepickerRangeValue } from 'saturn-datepicker';
import { environment as env } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ExportApiService {

  constructor(private http: HttpClient) { }

  private get system() {
    return localStorage.getItem('auditSystemSelected');
  }

  private get systemUrl() {
    return `${env.apiUrl}/audit/${this.system}`;
  }

  private get invoicesUrl() {
    return `${this.systemUrl}/invoices`;
  }

  private prepareDateRangeQuery(dateRange?: SatDatepickerRangeValue<Moment>): object {
    const query = {};

    if (dateRange && dateRange.begin) {
      query['uploadedAfter'] = dateRange.begin.toISOString();
    }
    if (dateRange && dateRange.end) {
      const end = dateRange.end.add(1, 'day');
      query['uploadedBefore'] = end.toISOString();
    }
    if ((dateRange && dateRange.begin) || (dateRange && dateRange.end)) {
      // tslint:disable-next-line:no-console
      console.debug(
        `received dateRange, begin ${dateRange.begin.toISOString()}, end ${dateRange.end.toISOString()}`,
      );
    }
    return query;
  }

  bill(
    q: string = '',
    dateRange?: SatDatepickerRangeValue<Moment>,
  ): Observable<string> {
    q = q.trim();
    if (q.length === 0) {
      return this.billInvoicesByDaterange(dateRange);
    } else if (q.length === 36) {
      // assume UUID
      return this.billInvoiceById(q);
    } else if (q.length === 64) {
      // assume hash of invoice
      return this.billInvoicesByHashValue(q);
    } else if (q.length === 129) {
      // assume `:`-separated hashes of accounting period
      return this.billInvoicesByPeriod(q);
    } else if (/^\d+$/.test(q)) {
      // query is number, first try reception id
      return this.billInvoicesByReceptionId(q).pipe(
        flatMap((invoices) => {
          if (invoices) {
            return of(invoices);
          } else {
            // then try statement id
            return this.billInvoicesByStatementId(q);
          }
        }),
      );
    } else {
      // no other queries are supported at the moment
      return EMPTY;
    }
  }

  export(
    q: string = '',
    dateRange?: SatDatepickerRangeValue<Moment>,
  ): Observable<string> {
    q = q.trim();
    if (q.length === 0) {
      return this.exportInvoicesByDateRange(dateRange);
    } else if (q.length === 36) {
      // assume UUID
      return this.exportInvoiceById(q);
    } else if (q.length === 64) {
      // assume hash of invoice
      return this.exportInvoicesByHashValue(q);
    } else if (q.length === 129) {
      // assume `:`-separated hashes of accounting period
      return this.exportInvoicesByPeriod(q);
    } else if (/^\d+$/.test(q)) {
      // query is number
      return this.exportInvoicesByReceptionId(q).pipe(
        flatMap((invoices) => {
          if (invoices) {
            return of(invoices);
          } else {
            return this.exportInvoicesByStatementId(q);
          }
        }),
      );
    } else {
      // no other queries are supported at the moment
      return EMPTY;
    }
  }

  exportInvoicesByDateRange(dateRange?: SatDatepickerRangeValue<Moment>): Observable<string> {
    const query = this.prepareDateRangeQuery(dateRange);
    return this.exportInvoices(query);
  }

  exportInvoicesByHashValue(hash: string) {
    return this.exportInvoices({ hash });
  }

  exportInvoicesByReceptionId(receptionId: string): Observable<string> {
    return this.exportInvoices({ receptionId });
  }

  exportInvoicesByStatementId(statementId: string): Observable<string> {
    return this.exportInvoices({ statementId });
  }

  exportInvoicesByPeriod(period: string) {
    return this.exportInvoices({ period });
  }

  exportInvoices(query: { [key: string]: any } = {}): Observable<string> {
    const options = {
      params: new HttpParams({ fromObject: query }),
      responseType: 'text' as 'text',
    };
    return this.http.get(`${this.systemUrl}/export`, options);
  }

  exportInvoiceById(uuid: string): Observable<string> {
    return this.http.get(`${this.invoicesUrl}/${uuid}/export`, {
      responseType: 'text' as 'text',
    });
  }

  billInvoicesByDaterange(dateRange?: SatDatepickerRangeValue<Moment>) {
    const query = this.prepareDateRangeQuery(dateRange);
    return this.exportInvoices(query);
  }

  billInvoicesByHashValue(hash: string) {
    return this.billInvoices({ hash });
  }

  billInvoiceById(uuid: string): Observable<string> {
    return this.http.get(`${this.invoicesUrl}/${uuid}/billing`, {
      responseType: 'text' as 'text',
    });
  }

  billInvoicesByPeriod(period: string) {
    return this.billInvoices({ period });
  }

  billInvoicesByReceptionId(receptionId: string): Observable<string> {
    return this.billInvoices({ receptionId });
  }

  billInvoicesByStatementId(statementId: string): Observable<string> {
    return this.billInvoices({ statementId });
  }

  billInvoices(query: { [key: string]: any } = {}): Observable<string> {
    const options = {
      params: new HttpParams({ fromObject: query }),
      responseType: 'text' as 'text',
    };
    return this.http.get(`${this.systemUrl}/billing`, options);
  }

}
