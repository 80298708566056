import { HttpClient, HttpParams } from '@angular/common/http';
import { EMPTY, of, throwError } from 'rxjs';
import { flatMap, map, switchMap } from 'rxjs/operators';
import { environment as env } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ApiService = /** @class */ (function () {
    function ApiService(http) {
        this.http = http;
    }
    Object.defineProperty(ApiService.prototype, "system", {
        get: function () {
            return localStorage.getItem('auditSystemSelected');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ApiService.prototype, "systemUrl", {
        get: function () {
            return env.apiUrl + "/audit/" + this.system;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ApiService.prototype, "invoicesUrl", {
        get: function () {
            return this.systemUrl + "/invoices";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ApiService.prototype, "periodsUrl", {
        get: function () {
            return this.systemUrl + "/periods";
        },
        enumerable: true,
        configurable: true
    });
    ApiService.prototype.search = function (q, pageIndex, pageSize, sortActive, sortDirection, dateRange) {
        var _this = this;
        if (q === void 0) { q = ''; }
        if (pageIndex === void 0) { pageIndex = 0; }
        if (pageSize === void 0) { pageSize = 20; }
        if (sortActive === void 0) { sortActive = 'reception_id'; }
        if (sortDirection === void 0) { sortDirection = 'desc'; }
        q = q.trim();
        if (q.length === 0) {
            var query = { pageIndex: pageIndex, pageSize: pageSize, sortActive: sortActive, sortDirection: sortDirection };
            if (dateRange && dateRange.begin) {
                query['uploadedAfter'] = dateRange.begin.format('YYYY-MM-DD') + 'T00:00:00.000Z';
            }
            if (dateRange && dateRange.end) {
                query['uploadedBefore'] = dateRange.end.format('YYYY-MM-DD') + 'T23:59:59.999Z';
            }
            if ((dateRange && dateRange.begin) || (dateRange && dateRange.end)) {
                // tslint:disable-next-line:no-console
                console.debug("received dateRange, begin " + dateRange.begin.toISOString() + ", end " + dateRange.end.toISOString());
            }
            return this.getInvoices(query);
        }
        else if (q.length === 36) {
            // assume UUID
            return this.getInvoiceById(q).pipe(map(function (invoice) {
                return ({ rows: 1, invoices: [invoice] });
            }));
        }
        else if (q.length === 64) {
            // assume hash of invoice
            return this.getInvoices({
                hash: q,
                pageIndex: pageIndex,
                pageSize: pageSize,
                sortActive: sortActive,
                sortDirection: sortDirection,
            });
        }
        else if (q.length === 129) {
            // assume `:`-separated hashes of accounting period
            return this.getInvoices({
                period: q,
                pageIndex: pageIndex,
                pageSize: pageSize,
                sortActive: sortActive,
                sortDirection: sortDirection,
            });
        }
        else if (/^\d+$/.test(q)) {
            // query is number
            return this.getInvoicesByReceptionId(q).pipe(flatMap(function (invoices) {
                if (invoices.rows > 0) {
                    return of(invoices);
                }
                else {
                    return _this.getInvoices({
                        statementId: q,
                        pageIndex: pageIndex,
                        pageSize: pageSize,
                        sortActive: sortActive,
                        sortDirection: sortDirection,
                    });
                }
            }));
        }
        else {
            // no other queries are supported at the moment
            return EMPTY;
        }
    };
    ApiService.prototype.getAccountingPeriods = function () {
        return this.http.get(this.periodsUrl);
    };
    ApiService.prototype.getInvoicesByReceptionId = function (receptionId) {
        return this.getInvoices({ receptionId: receptionId });
    };
    ApiService.prototype.getSingleInvoice = function (query) {
        if (query === void 0) { query = {}; }
        return this.getInvoices(query).pipe(switchMap(function (invoiceApi) {
            if (invoiceApi.rows !== 1) {
                var error = new Error('unexpected number of results. expected: 1; was: ' + invoiceApi.rows);
                return throwError(error);
            }
            else {
                return of(invoiceApi.invoices[0]);
            }
        }));
    };
    ApiService.prototype.getInvoices = function (query) {
        if (query === void 0) { query = {}; }
        var options = {
            params: new HttpParams({ fromObject: query }),
        };
        return this.http.get(this.invoicesUrl, options);
    };
    ApiService.prototype.getInvoicePdf = function (uuid) {
        return this.http.get(this.invoicesUrl + "/" + uuid + "/pdf", {
            responseType: 'blob',
        });
    };
    ApiService.prototype.getInvoiceById = function (uuid) {
        return this.http.get(this.invoicesUrl + "/" + uuid);
    };
    ApiService.prototype.getExpenseById = function (uuid) {
        return this.http.get(this.invoicesUrl + "/" + uuid + "/expense");
    };
    ApiService.ngInjectableDef = i0.defineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.inject(i1.HttpClient)); }, token: ApiService, providedIn: "root" });
    return ApiService;
}());
export { ApiService };
