import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Invoice } from 'app/model/invoice.model';
import { Moment } from 'moment';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { flatMap, map, switchMap } from 'rxjs/operators';
import { SatDatepickerRangeValue } from 'saturn-datepicker';
import { AccountingPeriod } from '../model/accounting-period.model';
import { Expense } from '../model/expense.model';
import { InvoicesApi } from '../model/invoice-api.model';
import { environment as env } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  constructor(private http: HttpClient) {}

  private get system() {
    return localStorage.getItem('auditSystemSelected');
  }

  private get systemUrl() {
    return `${env.apiUrl}/audit/${this.system}`;
  }

  private get invoicesUrl() {
    return `${this.systemUrl}/invoices`;
  }

  private get periodsUrl() {
    return `${this.systemUrl}/periods`;
  }

  search(
    q: string = '',
    pageIndex: number = 0,
    pageSize: number = 20,
    sortActive: string = 'reception_id',
    sortDirection: string = 'desc',
    dateRange?: SatDatepickerRangeValue<Moment>,
  ): Observable<InvoicesApi> {
    q = q.trim();
    if (q.length === 0) {
      const query = { pageIndex, pageSize, sortActive, sortDirection };

      if (dateRange && dateRange.begin) {
        query['uploadedAfter'] = dateRange.begin.format('YYYY-MM-DD') + 'T00:00:00.000Z';
      }
      if (dateRange && dateRange.end) {
        query['uploadedBefore'] = dateRange.end.format('YYYY-MM-DD') + 'T23:59:59.999Z';
      }
      if ((dateRange && dateRange.begin) || (dateRange && dateRange.end)) {
        // tslint:disable-next-line:no-console
        console.debug(
          `received dateRange, begin ${dateRange.begin.toISOString()}, end ${dateRange.end.toISOString()}`,
        );
      }
      return this.getInvoices(query);
    } else if (q.length === 36) {
      // assume UUID
      return this.getInvoiceById(q).pipe(
        map(
          (invoice: Invoice) =>
            ({ rows: 1, invoices: [invoice] } as InvoicesApi),
        ),
      );
    } else if (q.length === 64) {
      // assume hash of invoice
      return this.getInvoices({
        hash: q,
        pageIndex,
        pageSize,
        sortActive,
        sortDirection,
      });
    } else if (q.length === 129) {
      // assume `:`-separated hashes of accounting period
      return this.getInvoices({
        period: q,
        pageIndex,
        pageSize,
        sortActive,
        sortDirection,
      });
    } else if (/^\d+$/.test(q)) {
      // query is number
      return this.getInvoicesByReceptionId(q).pipe(
        flatMap((invoices: InvoicesApi) => {
          if (invoices.rows > 0) {
            return of(invoices);
          } else {
            return this.getInvoices({
              statementId: q,
              pageIndex,
              pageSize,
              sortActive,
              sortDirection,
            });
          }
        }),
      );
    } else {
      // no other queries are supported at the moment
      return EMPTY;
    }
  }

  getAccountingPeriods(): Observable<AccountingPeriod[]> {
    return this.http.get<AccountingPeriod[]>(this.periodsUrl);
  }

  getInvoicesByReceptionId(receptionId: string): Observable<InvoicesApi> {
    return this.getInvoices({ receptionId });
  }

  getSingleInvoice(query: { [key: string]: any } = {}): Observable<Invoice> {
    return this.getInvoices(query).pipe(
      switchMap((invoiceApi) => {
        if (invoiceApi.rows !== 1) {
          const error = new Error('unexpected number of results. expected: 1; was: ' + invoiceApi.rows);
          return throwError(error);
        } else {
          return of(invoiceApi.invoices[0]);
        }
      }));
  }

  getInvoices(query: { [key: string]: any } = {}): Observable<InvoicesApi> {
    const options = {
      params: new HttpParams({ fromObject: query }),
    };
    return this.http.get<InvoicesApi>(this.invoicesUrl, options);
  }

  getInvoicePdf(uuid: string): Observable<Blob> {
    return this.http.get(`${this.invoicesUrl}/${uuid}/pdf`, {
      responseType: 'blob',
    });
  }

  getInvoiceById(uuid: string): Observable<Invoice> {
    return this.http.get<Invoice>(`${this.invoicesUrl}/${uuid}`);
  }

  getExpenseById(uuid: string): Observable<Expense> {
    return this.http.get<any>(`${this.invoicesUrl}/${uuid}/expense`);
  }
}
