/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error-display.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./error-display.component";
import * as i3 from "@angular/material/snack-bar";
var styles_ErrorDisplayComponent = [i0.styles];
var RenderType_ErrorDisplayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorDisplayComponent, data: {} });
export { RenderType_ErrorDisplayComponent as RenderType_ErrorDisplayComponent };
export function View_ErrorDisplayComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "error-display-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "i", [["class", "material-icons btn-error-display-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["close"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 1, 0, currVal_0); }); }
export function View_ErrorDisplayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-error-display", [], null, null, null, View_ErrorDisplayComponent_0, RenderType_ErrorDisplayComponent)), i1.ɵdid(1, 49152, null, 0, i2.ErrorDisplayComponent, [i3.MatSnackBarRef, i3.MAT_SNACK_BAR_DATA], null, null)], null, null); }
var ErrorDisplayComponentNgFactory = i1.ɵccf("app-error-display", i2.ErrorDisplayComponent, View_ErrorDisplayComponent_Host_0, {}, {}, []);
export { ErrorDisplayComponentNgFactory as ErrorDisplayComponentNgFactory };
