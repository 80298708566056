import { formatDate } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment as env } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(http) {
        this.http = http;
        this.loginUrl = env.apiUrl + "/authentication/system";
    }
    AuthenticationService.prototype.login = function (username, password) {
        var httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + btoa(username + ':' + password),
            }),
        };
        return this.http.get(this.loginUrl, httpOptions)
            .pipe(map(function (data) {
            // login successful if there's a user in the response
            if (data) {
                localStorage.setItem('auditBasicAuth', window.btoa(username + ':' + password));
                localStorage.setItem('auditSystem', JSON.stringify(data));
                localStorage.setItem('auditSystemSelected', data[0]);
                localStorage.setItem('auditUser', username);
                localStorage.setItem('lastLogin', formatDate(new Date(), 'medium', 'en-us'));
            }
            return data;
        }));
    };
    AuthenticationService.prototype.logout = function () {
        // remove user from local storage to log user out
        localStorage.removeItem('auditBasicAuth');
        localStorage.removeItem('auditSystem');
        localStorage.removeItem('auditSystemSelected');
        localStorage.removeItem('auditUser');
        localStorage.removeItem('lastLogin');
    };
    AuthenticationService.ngInjectableDef = i0.defineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.inject(i1.HttpClient)); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}());
export { AuthenticationService };
