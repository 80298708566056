import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-error-display',
  templateUrl: './error-display.component.html',
  styleUrls: ['./error-display.component.scss'],
})
export class ErrorDisplayComponent {

  constructor(public snackBarRef: MatSnackBarRef<ErrorDisplayComponent>, @Inject(MAT_SNACK_BAR_DATA) public data: any) {}

  public dismiss(): void  {
    this.snackBarRef.dismiss();
  }
}
