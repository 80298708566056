import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorDisplayService } from '../error-display/error-display.service';
import { TranslationUtils } from '../shared/translation.utils';
import { AuthenticationService } from './authentication.service';
var ErrorInterceptor = /** @class */ (function () {
    function ErrorInterceptor(router, authenticationService, translationUtils, errorDisplayService) {
        this.router = router;
        this.authenticationService = authenticationService;
        this.translationUtils = translationUtils;
        this.errorDisplayService = errorDisplayService;
    }
    ErrorInterceptor.prototype.errorOpen = function (error_code) {
        var errorMessage = this.translationUtils.translateError(error_code);
        this.errorDisplayService.open(errorMessage);
    };
    ErrorInterceptor.prototype.intercept = function (request, next) {
        var _this = this;
        return next.handle(request).pipe(catchError(function (error) {
            if (error.status === 401) {
                // Unauthorized
                // auto logout if 401 response returned from api
                _this.authenticationService.logout();
                _this.errorOpen(TranslationUtils.NOT_AUTHORIZED_ERROR);
                _this.router.navigate(['/login']);
            }
            else if (error.status === 0) {
                _this.errorOpen(TranslationUtils.NETWORK_ERROR);
            }
            else {
                _this.errorOpen((error.error && error.error.err && error.error.err.error_code)
                    ? error.error.err.error_code
                    : TranslationUtils.UNKNOWN_ERROR);
            }
            return throwError(error);
        }));
    };
    return ErrorInterceptor;
}());
export { ErrorInterceptor };
