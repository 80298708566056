/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./admin-layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/navbar/navbar.component.ngfactory";
import * as i3 from "../../components/navbar/navbar.component";
import * as i4 from "@angular/router";
import * as i5 from "../../components/sidebar/sidebar.component.ngfactory";
import * as i6 from "../../components/sidebar/sidebar.component";
import * as i7 from "@ngx-translate/i18n-polyfill";
import * as i8 from "./admin-layout.component";
import * as i9 from "@angular/common";
var styles_AdminLayoutComponent = [i0.styles];
var RenderType_AdminLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdminLayoutComponent, data: {} });
export { RenderType_AdminLayoutComponent as RenderType_AdminLayoutComponent };
export function View_AdminLayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-navbar", [], null, null, null, i2.View_NavbarComponent_0, i2.RenderType_NavbarComponent)), i1.ɵdid(2, 114688, null, 0, i3.NavbarComponent, [i1.ElementRef, i4.Router], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "sidebar"], ["data-background-color", "white"], ["data-color", "purple"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-sidebar", [], null, null, null, i5.View_SidebarComponent_0, i5.RenderType_SidebarComponent)), i1.ɵdid(5, 114688, null, 0, i6.SidebarComponent, [i7.I18n], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "main-panel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(8, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 5, 0); _ck(_v, 8, 0); }, null); }
export function View_AdminLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-admin-layout", [], null, null, null, View_AdminLayoutComponent_0, RenderType_AdminLayoutComponent)), i1.ɵdid(1, 4308992, null, 0, i8.AdminLayoutComponent, [i9.Location, i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminLayoutComponentNgFactory = i1.ɵccf("app-admin-layout", i8.AdminLayoutComponent, View_AdminLayoutComponent_Host_0, {}, {}, []);
export { AdminLayoutComponentNgFactory as AdminLayoutComponentNgFactory };
