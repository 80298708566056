var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MatPaginatorIntl } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
var MatPaginatorIntlService = /** @class */ (function (_super) {
    __extends(MatPaginatorIntlService, _super);
    function MatPaginatorIntlService(i18n) {
        var _this = _super.call(this) || this;
        _this.i18n = i18n;
        _this.getRangeLabel = function (page, pageSize, length) {
            if (length === 0 || pageSize === 0) {
                return _this.i18n({ value: '0 of {{length}}', id: 'paginator_label_zero-range' }, { length: length });
            }
            length = Math.max(length, 0);
            var startIndex = page * pageSize;
            var endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
            return _this.i18n({ value: '{{startIndex}} - {{endIndex}} of {{length}}', id: 'paginator_label_ramge' }, { startIndex: startIndex + 1, endIndex: endIndex, length: length });
        };
        _this.itemsPerPageLabel = _this.i18n({ value: 'Items per page:', id: 'table-paginator_label_items-per-page' });
        _this.nextPageLabel = _this.i18n({ value: 'Next page', id: 'table-paginator_label_next-page' });
        _this.previousPageLabel = _this.i18n({ value: 'Previous page', id: 'table-paginator_label_previous-page' });
        _this.firstPageLabel = _this.i18n({ value: 'First page', id: 'table-paginator_label_first-page' });
        _this.lastPageLabel = _this.i18n({ value: 'Last page', id: 'table-paginator_label_last-page' });
        return _this;
    }
    return MatPaginatorIntlService;
}(MatPaginatorIntl));
export { MatPaginatorIntlService };
