import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ErrorDisplayComponent } from './error-display.component';
import { ErrorDisplayService } from './error-display.service';

@NgModule({
  imports: [
    MatButtonModule,
    MatSnackBarModule,
  ],
  declarations: [ ErrorDisplayComponent ],
  providers: [ ErrorDisplayService ],
  entryComponents: [ ErrorDisplayComponent ],
})
export class ErrorDisplayModule {}
