<!--
<div class="main-header">
  <div class="brand">
    <a href="/dashboard">
      <img src="/assets/cs.png"/>
      <svg height="16px" width="120px" focusable="false" class="icon-amadeus">
        <use xlink:href="/assets/icons.svg#amadeus_logo"
             xmlns:xlink="http://www.w3.org/1999/xlink"></use>
      </svg>
      <div class="app-name">
        CS Audit
      </div>
    </a>
  </div>

</div>
-->
<div class="wrapper">
  <app-navbar></app-navbar>
  <div class="sidebar" data-color="purple" data-background-color="white">
    <app-sidebar></app-sidebar>
  </div>
  <div class="main-panel">
    <router-outlet></router-outlet>
    <!-- <div *ngIf="isMaps('maps')">
        <app-footer></app-footer>
    </div> -->
  </div>
</div>
