import { HttpClient, HttpParams } from '@angular/common/http';
import { EMPTY, of } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { environment as env } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ExportApiService = /** @class */ (function () {
    function ExportApiService(http) {
        this.http = http;
    }
    Object.defineProperty(ExportApiService.prototype, "system", {
        get: function () {
            return localStorage.getItem('auditSystemSelected');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExportApiService.prototype, "systemUrl", {
        get: function () {
            return env.apiUrl + "/audit/" + this.system;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExportApiService.prototype, "invoicesUrl", {
        get: function () {
            return this.systemUrl + "/invoices";
        },
        enumerable: true,
        configurable: true
    });
    ExportApiService.prototype.prepareDateRangeQuery = function (dateRange) {
        var query = {};
        if (dateRange && dateRange.begin) {
            query['uploadedAfter'] = dateRange.begin.toISOString();
        }
        if (dateRange && dateRange.end) {
            var end = dateRange.end.add(1, 'day');
            query['uploadedBefore'] = end.toISOString();
        }
        if ((dateRange && dateRange.begin) || (dateRange && dateRange.end)) {
            // tslint:disable-next-line:no-console
            console.debug("received dateRange, begin " + dateRange.begin.toISOString() + ", end " + dateRange.end.toISOString());
        }
        return query;
    };
    ExportApiService.prototype.bill = function (q, dateRange) {
        var _this = this;
        if (q === void 0) { q = ''; }
        q = q.trim();
        if (q.length === 0) {
            return this.billInvoicesByDaterange(dateRange);
        }
        else if (q.length === 36) {
            // assume UUID
            return this.billInvoiceById(q);
        }
        else if (q.length === 64) {
            // assume hash of invoice
            return this.billInvoicesByHashValue(q);
        }
        else if (q.length === 129) {
            // assume `:`-separated hashes of accounting period
            return this.billInvoicesByPeriod(q);
        }
        else if (/^\d+$/.test(q)) {
            // query is number, first try reception id
            return this.billInvoicesByReceptionId(q).pipe(flatMap(function (invoices) {
                if (invoices) {
                    return of(invoices);
                }
                else {
                    // then try statement id
                    return _this.billInvoicesByStatementId(q);
                }
            }));
        }
        else {
            // no other queries are supported at the moment
            return EMPTY;
        }
    };
    ExportApiService.prototype.export = function (q, dateRange) {
        var _this = this;
        if (q === void 0) { q = ''; }
        q = q.trim();
        if (q.length === 0) {
            return this.exportInvoicesByDateRange(dateRange);
        }
        else if (q.length === 36) {
            // assume UUID
            return this.exportInvoiceById(q);
        }
        else if (q.length === 64) {
            // assume hash of invoice
            return this.exportInvoicesByHashValue(q);
        }
        else if (q.length === 129) {
            // assume `:`-separated hashes of accounting period
            return this.exportInvoicesByPeriod(q);
        }
        else if (/^\d+$/.test(q)) {
            // query is number
            return this.exportInvoicesByReceptionId(q).pipe(flatMap(function (invoices) {
                if (invoices) {
                    return of(invoices);
                }
                else {
                    return _this.exportInvoicesByStatementId(q);
                }
            }));
        }
        else {
            // no other queries are supported at the moment
            return EMPTY;
        }
    };
    ExportApiService.prototype.exportInvoicesByDateRange = function (dateRange) {
        var query = this.prepareDateRangeQuery(dateRange);
        return this.exportInvoices(query);
    };
    ExportApiService.prototype.exportInvoicesByHashValue = function (hash) {
        return this.exportInvoices({ hash: hash });
    };
    ExportApiService.prototype.exportInvoicesByReceptionId = function (receptionId) {
        return this.exportInvoices({ receptionId: receptionId });
    };
    ExportApiService.prototype.exportInvoicesByStatementId = function (statementId) {
        return this.exportInvoices({ statementId: statementId });
    };
    ExportApiService.prototype.exportInvoicesByPeriod = function (period) {
        return this.exportInvoices({ period: period });
    };
    ExportApiService.prototype.exportInvoices = function (query) {
        if (query === void 0) { query = {}; }
        var options = {
            params: new HttpParams({ fromObject: query }),
            responseType: 'text',
        };
        return this.http.get(this.systemUrl + "/export", options);
    };
    ExportApiService.prototype.exportInvoiceById = function (uuid) {
        return this.http.get(this.invoicesUrl + "/" + uuid + "/export", {
            responseType: 'text',
        });
    };
    ExportApiService.prototype.billInvoicesByDaterange = function (dateRange) {
        var query = this.prepareDateRangeQuery(dateRange);
        return this.exportInvoices(query);
    };
    ExportApiService.prototype.billInvoicesByHashValue = function (hash) {
        return this.billInvoices({ hash: hash });
    };
    ExportApiService.prototype.billInvoiceById = function (uuid) {
        return this.http.get(this.invoicesUrl + "/" + uuid + "/billing", {
            responseType: 'text',
        });
    };
    ExportApiService.prototype.billInvoicesByPeriod = function (period) {
        return this.billInvoices({ period: period });
    };
    ExportApiService.prototype.billInvoicesByReceptionId = function (receptionId) {
        return this.billInvoices({ receptionId: receptionId });
    };
    ExportApiService.prototype.billInvoicesByStatementId = function (statementId) {
        return this.billInvoices({ statementId: statementId });
    };
    ExportApiService.prototype.billInvoices = function (query) {
        if (query === void 0) { query = {}; }
        var options = {
            params: new HttpParams({ fromObject: query }),
            responseType: 'text',
        };
        return this.http.get(this.systemUrl + "/billing", options);
    };
    ExportApiService.ngInjectableDef = i0.defineInjectable({ factory: function ExportApiService_Factory() { return new ExportApiService(i0.inject(i1.HttpClient)); }, token: ExportApiService, providedIn: "root" });
    return ExportApiService;
}());
export { ExportApiService };
