/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navbar.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./navbar.component";
var styles_NavbarComponent = [i0.styles];
var RenderType_NavbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavbarComponent, data: {} });
export { RenderType_NavbarComponent as RenderType_NavbarComponent };
export function View_NavbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 35, "nav", [["class", "navbar navbar-expand-lg fixed-top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "a", [["class", "navbar-brand"], ["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["src", "/assets/cs.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, ":svg:svg", [["class", "icon-amadeus"], ["focusable", "false"], ["height", "16px"], ["width", "120px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, ":svg:use", [[":xlink:href", "/assets/icons.svg#amadeus_logo"], [":xmlns:xlink", "http://www.w3.org/1999/xlink"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" CS Audit "])), (_l()(), i1.ɵeld(7, 0, null, null, 5, "button", [["class", "navbar-toggler"], ["mat-raised-button", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sidebarToggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Toggle navigation"])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "span", [["class", "navbar-toggler-icon icon-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "span", [["class", "navbar-toggler-icon icon-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "span", [["class", "navbar-toggler-icon icon-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 22, "div", [["class", "collapse navbar-collapse justify-content-end"], ["id", "navigation"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 21, "ul", [["class", "navbar-nav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 6, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 5, "a", [["class", "nav-link"], ["routerLink", "/dashboard"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["dashboard"])), (_l()(), i1.ɵeld(20, 0, null, null, 1, "span", [["class", "d-lg-none d-md-block"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Dashboard"])), (_l()(), i1.ɵeld(22, 0, null, null, 6, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 5, "a", [["class", "nav-link"], ["routerLink", "/invoice-list"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 24).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(24, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(25, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["list"])), (_l()(), i1.ɵeld(27, 0, null, null, 1, "span", [["class", "d-lg-none d-md-block"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Invoice-List"])), (_l()(), i1.ɵeld(29, 0, null, null, 6, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 5, "a", [["class", "nav-link"], ["routerLink", "/invoice-search"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 31).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(31, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(32, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["search"])), (_l()(), i1.ɵeld(34, 0, null, null, 1, "span", [["class", "d-lg-none d-md-block"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Search"]))], function (_ck, _v) { var currVal_2 = "/"; _ck(_v, 2, 0, currVal_2); var currVal_5 = "/dashboard"; _ck(_v, 17, 0, currVal_5); var currVal_8 = "/invoice-list"; _ck(_v, 24, 0, currVal_8); var currVal_11 = "/invoice-search"; _ck(_v, 31, 0, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 17).target; var currVal_4 = i1.ɵnov(_v, 17).href; _ck(_v, 16, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵnov(_v, 24).target; var currVal_7 = i1.ɵnov(_v, 24).href; _ck(_v, 23, 0, currVal_6, currVal_7); var currVal_9 = i1.ɵnov(_v, 31).target; var currVal_10 = i1.ɵnov(_v, 31).href; _ck(_v, 30, 0, currVal_9, currVal_10); }); }
export function View_NavbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navbar", [], null, null, null, View_NavbarComponent_0, RenderType_NavbarComponent)), i1.ɵdid(1, 114688, null, 0, i4.NavbarComponent, [i1.ElementRef, i2.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavbarComponentNgFactory = i1.ɵccf("app-navbar", i4.NavbarComponent, View_NavbarComponent_Host_0, {}, {}, []);
export { NavbarComponentNgFactory as NavbarComponentNgFactory };
