import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { ErrorDisplayComponent } from './error-display.component';

@Injectable({
    providedIn: 'root',
})
export class ErrorDisplayService {

    private snackBarRef: MatSnackBarRef<ErrorDisplayComponent>;

    constructor( private snackBar: MatSnackBar) {}

    public open(message: string): void {
        const config: MatSnackBarConfig<ErrorDisplayComponent> = {
            panelClass: ['error-display'],
            duration: 10000,
            verticalPosition: 'top',
        };
        this.snackBarRef = this.snackBar.openFromComponent(ErrorDisplayComponent, {data: message, ...config});
/*
        this.snackBarRef.afterOpened().subscribe(() => {
            console.log('The snack-bar was opened!');
        });

        this.snackBarRef.afterDismissed().subscribe(() => {
            console.log('The snack-bar was dismissed');
        });
*/
    }
}
