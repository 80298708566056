var BasicAuthInterceptor = /** @class */ (function () {
    function BasicAuthInterceptor() {
    }
    BasicAuthInterceptor.prototype.intercept = function (request, next) {
        // add authorization header with basic auth credentials if available
        var auditBasicAuth = localStorage.getItem('auditBasicAuth');
        if (auditBasicAuth) {
            request = request.clone({
                setHeaders: {
                    Authorization: "Basic " + auditBasicAuth,
                },
            });
        }
        return next.handle(request);
    };
    return BasicAuthInterceptor;
}());
export { BasicAuthInterceptor };
