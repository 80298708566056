import { RouterModule } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './shared/auth.guard';
var routes = [
    // { path: '', component: AdminLayoutComponent, canActivate: [AuthGuard], redirectTo: 'dashboard' },
    { path: 'login', component: LoginComponent },
    { path: 'logout', redirectTo: 'login' },
    { path: '',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        children: [{
                path: '',
                loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule',
            }],
    },
    // otherwise redirect to home
    { path: '**', component: AdminLayoutComponent },
];
export var routing = RouterModule.forRoot(routes, { useHash: true });
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
