import { OnInit } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
var SidebarComponent = /** @class */ (function () {
    function SidebarComponent(i18n) {
        this.i18n = i18n;
    }
    SidebarComponent.prototype.ngOnInit = function () {
        var routes = this.createRoutes();
        this.menuItems = routes.filter(function (menuItem) { return menuItem; });
    };
    SidebarComponent.prototype.isMobileMenu = function () {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };
    SidebarComponent.prototype.createRoutes = function () {
        return [
            { path: '/dashboard', title: this.i18n({ value: 'Dashboard', id: 'sidebar_dashboard' }), icon: 'dashboard', class: '' },
            { path: '/invoice-list', title: this.i18n({ value: 'Invoices', id: 'sidebar_invoice-list' }), icon: 'list', class: '' },
            { path: '/period-list', title: this.i18n({ value: 'Accounting Periods', id: 'sidebar_period-list' }), icon: 'list', class: '' },
            { path: '/logout', title: this.i18n({ value: 'Logout', id: 'sidebar_logout' }), icon: 'logout', class: '' },
        ];
    };
    return SidebarComponent;
}());
export { SidebarComponent };
