<nav class="navbar navbar-expand-lg fixed-top">
  <a class="navbar-brand" routerLink="/">
    <img src="/assets/cs.png"/>
    <svg height="16px" width="120px" focusable="false" class="icon-amadeus">
      <use xlink:href="/assets/icons.svg#amadeus_logo"
           xmlns:xlink="http://www.w3.org/1999/xlink"></use>
    </svg>
    CS Audit
  </a>


  <button mat-raised-button class="navbar-toggler" type="button" (click)="sidebarToggle()">
    <span class="sr-only">Toggle navigation</span>
    <span class="navbar-toggler-icon icon-bar"></span>
    <span class="navbar-toggler-icon icon-bar"></span>
    <span class="navbar-toggler-icon icon-bar"></span>
  </button>
  <div class="collapse navbar-collapse justify-content-end" id="navigation">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" routerLink="/dashboard">
          <i class="material-icons">dashboard</i>
          <span class="d-lg-none d-md-block" i18n="@@navbar_item_dashboard">Dashboard</span>
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link" routerLink="/invoice-list">
          <i class="material-icons">list</i>
          <span class="d-lg-none d-md-block" i18n="@@navbar_item_invoice-list">Invoice-List</span>
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link" routerLink="/invoice-search">
          <i class="material-icons">search</i>
          <span class="d-lg-none d-md-block" i18n="@@navbar_item_invoice-search">Search</span>
        </a>
      </li>
    </ul>
  </div>
</nav>
